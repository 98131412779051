
import './App.css';

//components 
import DataProvider from './context/DataProvider';

import {BrowserRouter, Routes, Route} from 'react-router-dom'

// import Login from './components/account/Login';
import Uni from './components/Unity/unity';
import Map from './components/Map'; // Context.Provider를 가져옴
// import Gongeop from './components/gongeop/gongeop';
// import Uni2 from './components/Unity/unity_down.jsx';
// import Socket from './components/socket_test/socket'
// import MYS from './components/DB/mysql';
// import Mdbus from './components/Unity/modebus';

function App() {
  return (
    <DataProvider>
      <BrowserRouter>
        <Routes>
          {/* <Route path='/gongeop' element={<Gongeop />} /> */}
          <Route path='/unity' element={<Uni />} />
          <Route path='/map' element={<Map />} />
          <Route path='/' element={<Map />} />
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
}

export default App;
