import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import EditIcon from '@mui/icons-material/Edit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import FileSaver from 'file-saver'

const Floatingbtn = () => {
    const [open, setOpen] = useState(false);
    const [text, setText] = useState('');

    const downloadFile = (filename) => {
        axios.get(`http://61.76.198.59:81/download/${filename}`, {
            responseType: 'blob'
        }).then((response) => {
            FileSaver.saveAs(new Blob([response.data]), filename);
        }).catch((error) => {
            console.error('Error downloading file444:', error);
        });
    };


    // Function to send memo
    const Send_memo = () => {
        axios.post("http://61.76.198.59:81/save-data", { memo: text })
            .then((res) => {
                const { filePath } = res.data;
                const filename = filePath.split('\\').pop(); // 파일 이름 추출
                // 다운로드 요청을 보냅니다.
                downloadFile(filename);
            })
            .catch((error) => {
                console.error('Error:', error);
            });
    };

    useEffect(() => {
        if (open) {
            localStorage.setItem('open', true);
        } else {
            localStorage.removeItem('open'); // 저장된 item을 삭제
        }
    }, [open]);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setText('');
    };

    const handleSubmit = () => {
        Send_memo();
        handleClose(); // Close the dialog after submission
    };

    const handleChange = (e) => {
        setText(e.target.value);
    };

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    '& > :not(style)': { m: 1 },
                }}
            >
                <Fab variant="extended" color="primary" onClick={handleClickOpen}>
                    <EditIcon sx={{ mr: 1 }} />
                    일지 작성
                </Fab>
            </Box>

            {/* Dialog Component */}
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>일지 작성</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="일지를 작성해주세요"
                        type="text"
                        fullWidth
                        variant="outlined"
                        value={text}
                        onChange={handleChange}
                        multiline
                        rows={6}
                        inputProps={{ 'aria-label': '일지를 작성해주세요' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        취소
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        저장
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default Floatingbtn;
