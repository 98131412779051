import React from 'react';
import { Button, Box } from '@mui/material';
import axios from 'axios';
import FileSaver from 'file-saver'

function WeeklyBtn() {

    const WeeklydownloadFile = () => {

        axios.get(`http://61.76.198.59:81/weeklyReport`, {
            responseType: 'blob'
        }).then((response) => {
            FileSaver.saveAs(new Blob([response.data]), 'Weekly_Report.xlsx');
        }).catch((error) => {
            console.error('Error downloading file444:', error);
        });
    };

    const handleSendOn = () => {
        WeeklydownloadFile()
    };
    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 80, // 화면 하단에서의 거리
                right: 100, // 화면 오른쪽에서의 거리
                display: 'flex',
            }}
        >
            <Button variant="contained" color="primary" onClick={handleSendOn}>
                주간
            </Button>
        </Box>
    );
}

export default WeeklyBtn;