import React, { useState, useEffect } from 'react'
import MapContext from './MapContext'
import { useNavigate } from 'react-router-dom';
import 'ol/ol.css'
import { Map as OlMap, View } from 'ol'
import { defaults as defaultControls, FullScreen } from 'ol/control'
import { fromLonLat, get as getProjection } from 'ol/proj'
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer'
import { XYZ, Vector as VectorSource } from 'ol/source'
import { Point } from 'ol/geom'
import { Feature } from 'ol'
import { Style, Icon } from 'ol/style'

// import { Modify } from 'ol/interaction'
import iconsrc from '../../data/icon.png';
import {
    DragRotateAndZoom,
    defaults as defaultInteractions,
} from 'ol/interaction'

const Map = ({ children }) => {
    const [mapObj, setMapObj] = useState({}) // Initialize as an empty object
    const navigate = useNavigate();
    useEffect(() => {
        // Map 객체 생성 및 vworld 지도 설정
        const map = new OlMap({
            controls: defaultControls({ zoom: false, rotate: false }).extend([
                new FullScreen(),
            ]),
            interactions: defaultInteractions().extend([new DragRotateAndZoom()]),
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'http://api.vworld.kr/req/wmts/1.0.0/0ED41364-EAC4-35A8-957D-E4783EA04C58/Satellite/{z}/{y}/{x}.jpeg',
                    }),
                }),
            ],
            target: 'map',
            view: new View({
                projection: getProjection('EPSG:3857'),
                center: fromLonLat(
                    // [129.247041, 35.3677254],
                    [127.929364, 36.636193],
                    getProjection('EPSG:3857')
                ),
                zoom: 8,
            }),
        })

        // 마커 생성
        const marker = new Feature({
            geometry: new Point(fromLonLat([129.247460, 35.36789000])),
        })

        // 마커 스타일 설정
        marker.setStyle(
            new Style({
                image: new Icon({
                    anchor: [0.5, 1],
                    src: iconsrc, // 마커 아이콘 URL
                    scale: 0.1,
                }),
            })
        )

        // 벡터 소스 및 레이어 생성
        const vectorSource = new VectorSource({
            features: [marker],
        })

        const vectorLayer = new VectorLayer({
            source: vectorSource,
        })



        // 마커 클릭 이벤트 
        map.on('click', (evt) => {
            const feature = map.forEachFeatureAtPixel(evt.pixel,
                (feature) => {
                    return feature;
                });

            if (feature) {
                const targetCenter = fromLonLat([129.247580, 35.367770000]);

                const animateZoomAndPan = () => {
                    const view = map.getView();
                    const currentZoom = view.getZoom();
                    const currentCenter = view.getCenter();

                    if(currentZoom < 20.5)
                    {
                        const change_move = 0.25
                        const change_zoom = 0.4
                        view.animate({
                            center: [
                                currentCenter[0] + (targetCenter[0] - currentCenter[0]) * change_move,
                                currentCenter[1] + (targetCenter[1] - currentCenter[1]) * change_move
                            ],
                            zoom: currentZoom + change_zoom,
                            duration: 50
                        }, animateZoomAndPan);
                    }
                    else
                    {
                        setTimeout(() => {
                            navigate('/unity');
                        }, 1000);
                    }
                };

                animateZoomAndPan();
            }
        })

        // 벡터 레이어를 맵에 추가
        map.addLayer(vectorLayer)
        // map.addInteraction(modify)
        

        setMapObj({ map })
        return () => map.setTarget(undefined)
    }, [navigate])

    return (
        <div>
            <div id="map" style={{ width: '100vw', height: '100vh' }}></div>
            <MapContext.Provider value={mapObj}>{children}</MapContext.Provider>
        </div>
    )
}

export default Map
