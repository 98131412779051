import React, { useEffect, useState, useCallback } from "react";
import { Unity, useUnityContext } from "react-unity-webgl";
import Floatingbtn from '../Button/floatingbtn';
import io from 'socket.io-client';
import DailyBtn from "../Button/dailybtn";
import WeeklyBtn from "../Button/weeklybtn";
import MonthlyBtn from "../Button/monthlybtn";
// import Loading from '../Loading/Loading';

// const socket = io.connect(process.env.REACT_APP_Server_IP); // 서버의 IP 주소 확인
const socket = io.connect("http://61.76.198.59:81");
function Uni() { 
    // 서버 전송 메세지 관리 (message: 1씩 증가, message2: 2씩 증가, message3: 3씩 증가)
    const [message, setMessage] = useState(0);
    const [message2, setMessage2] = useState(0);
    const [isUnityLoaded, setIsUnityLoaded] = useState(false);

    const { unityProvider, sendMessage, addEventListener, removeEventListener, isLoaded, unload } = useUnityContext({
        loaderUrl: "Build/20241010_v3.loader.js",
        dataUrl: "Build/20241010_v3.data",
        frameworkUrl: "Build/20241010_v3.framework.js",
        codeUrl: "Build/20241010_v3.wasm",
    });

    const sendToken = useCallback(() => {
        if (isUnityLoaded) {
            sendMessage('Trigger', 'Recievemod3', message2.toString());
            sendMessage('Trigger', 'Recievemod0', message.toString());
            if (localStorage.getItem('open')) {
                sendMessage('Trigger', 'EnableInput', 'open');
            }
            else {
                sendMessage('Trigger', 'DisableInput', 'close');
            }
        }
    }, [isUnityLoaded, sendMessage, message, message2]);

    useEffect(() => {
        const handlemsg1 = (data) => {
            // console.log(data);
            setMessage(data);
        };
        const handlemsg2 = (data2) => {
            // console.log(data2);
            setMessage2(data2);
        };

        addEventListener('TakeTokenFromReact', sendToken);
        socket.on('0', handlemsg1);
        socket.on('3', handlemsg2);

        return () => {
            socket.off('0', handlemsg1);
            socket.off('3', handlemsg2);
            removeEventListener('TakeTokenFromReact', sendToken);
        };
    }, [sendToken, addEventListener, removeEventListener]);

    useEffect(() => {
        console.log('isLoaded:', isLoaded);
        if (isLoaded) {
            setIsUnityLoaded(true);
        }
    }, [isLoaded]);

    useEffect(() => {
        return () => {
            // 유니티 인스턴스를 안전하게 언로드
            if (isUnityLoaded) {
                unload().catch((error) => {
                    console.error("Failed to unload Unity instance:", error);
                });
            }
        };
    }, [isUnityLoaded, unload]);


    return (
        <div>
            
            <Unity unityProvider={unityProvider} style={{ width: '100%', height: '100vh', justifySelf: 'center', alignSelf: 'center' }} />
            
            <Floatingbtn />
            <DailyBtn />
            <WeeklyBtn />
            <MonthlyBtn />
        </div>
    );
}

export default Uni;
